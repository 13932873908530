<template>
  <div>
    <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />
    <div class="sectionButtons-actions">
      <CButton
        v-if="!loading"
        color="success"
        size="lg"
        class="sectionButtons-actionsItem"
        @click="exportXlsx()"
        >Export Results</CButton
      >
    </div>
    <CRow>
      <CCol>
        <ListAnswers
          v-if="!loading"
          v-bind:tableItems="listAnswers"
          v-bind:survey="survey"
        />
      </CCol>
    </CRow>
    <CToaster :autohide="3000" class="main-toast">
      <CToast :key="'toast'" :show="toastShow" :color="toastColor">
        {{ toastMessage }}
      </CToast>
    </CToaster>
  </div>
</template>

<script>
import ListAnswers from "../components/ListAnswers.vue";
// import CardDriver from "../components/CardDriver";

export default {
  name: "Surveys",
  components: {
    ListAnswers,
  },
  data() {
    return {
      loading: true,

      listAnswers: [],
      survey: null,

      // props toast
      toastShow: false,
      toastColor: "",
      toastMessage: "",
    };
  },
  async created() {
    await this.loadSurveys();
    this.loading = false;
  },
  methods: {
    async loadSurveys() {
      var self = this;
      console.log("loadSurveys", this.$route.params.id);
      const res = await self.$http.get(
        "apiConsole/console/getSurvey/" + this.$route.params.id
      );
      self.listAnswers = res.data.survey.answers;
      self.survey = res.data.survey;
    },
    async exportXlsx() {
      const self = this;

      const response = await self.$http(
        {
          url: "apiConsole/console/exportSurvey/" + this.$route.params.id,
          method: "GET",
          responseType: "blob",
        } // important
      );

      if (!window.navigator.msSaveOrOpenBlob) {
        // BLOB NAVIGATOR
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `MyTruck_Survey_${this.$route.params.id}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      } else {
        // BLOB FOR EXPLORER 11
        const url = window.navigator.msSaveOrOpenBlob(
          new Blob([response.data]),
          `MyTruck_Analytics_${this.$route.params.id}.xlsx`
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-toast {
  z-index: 11000;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.sectionButtons-actions {
  padding: 1em 0 2em 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sectionButtons-actionsItem {
  margin-left: 1em;
  font-size: 14px;
}
</style>