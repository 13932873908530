<template>
  <div>

    <CCard accent-color="primary">
      <CCardHeader color="white">
        <b class="text-dark">{{ tableTitle }}</b>
      </CCardHeader>
      <CCardBody>
        <!-- section search -->

        <CDataTable
          class="mb-0 table-outline"
          hover
          :items="tableItems"
          :fields="tableFields"
          head-color="light"
          no-sorting
          :items-per-page="10"
          pagination
          items-per-page-select
        >
          <template #answer_1="{ item }">
            <td>
              {{ item.answer_1 == 0 ? "No answer" : item.answer_1 }}
            </td>
          </template>
          <template #answer_2="{ item }">
            <td>
              {{ item.answer_2 == 0 ? "No answer" : item.answer_2 }}
            </td>
          </template>
          <template #answer_3="{ item }">
            <td>
              {{ item.answer_3 == 0 ? "No answer" : item.answer_3 }}
            </td>
          </template>
          <template #driver="{ item }">
            <td>
              {{ item.driver.name}}
            </td>
          </template>
            <template #driver_email="{ item }">
            <td>
              {{ item.driver.email}}
            </td>
          </template>
            <template #driver_phone="{ item }">
            <td>
              {{ item.driver.phoneNumber}}
            </td>
          </template>
          <template #answer_date="{ item }">
            <td style="white-space: nowrap;">
              {{ moment(item.answer_date).format("YYYY/MM/DD HH:mm (Z)") }}
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
const moment = require("moment");
import BlockColumn from "./BlockColumn";
import BlockItem from "./BlockItem";
import BlockInfoSelect from "./BlockInfoSelect";
export default {
  name: "ListAnswers",
  components: {
    BlockColumn,
    BlockItem,
    BlockInfoSelect,
  },
  props: {
    tableItems: Array,
    survey: Object,
  },
  data() {
    return {
      layout: "list",
      tableTitle: "Answers",
      lSurvey: this.survey,
      tableFields: [
        { key: "driver", label: "Driver", filter: true },
        { key: "driver_email", label: "Email" },
        { key: "driver_phone", label: "Phone" },
        { key: "answer_1", label: this.survey ? this.survey.question_1 : "" },
        { key: "answer_2", label: this.survey ? this.survey.question_2 : "" },
        { key: "answer_3", label: this.survey ? this.survey.question_3 : "" },
        { key: "answer_date", label: "Answer date" },
      ],

      listStatus: [],
    };
  },
  async created() {
    const self = this;
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>