var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{attrs:{"accent-color":"primary"}},[_c('CCardHeader',{attrs:{"color":"white"}},[_c('b',{staticClass:"text-dark"},[_vm._v(_vm._s(_vm.tableTitle))])]),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","no-sorting":"","items-per-page":10,"pagination":"","items-per-page-select":""},scopedSlots:_vm._u([{key:"answer_1",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.answer_1 == 0 ? "No answer" : item.answer_1)+" ")])]}},{key:"answer_2",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.answer_2 == 0 ? "No answer" : item.answer_2)+" ")])]}},{key:"answer_3",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.answer_3 == 0 ? "No answer" : item.answer_3)+" ")])]}},{key:"driver",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.driver.name)+" ")])]}},{key:"driver_email",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.driver.email)+" ")])]}},{key:"driver_phone",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.driver.phoneNumber)+" ")])]}},{key:"answer_date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.moment(item.answer_date).format("YYYY/MM/DD HH:mm (Z)"))+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }